import { navigate } from 'gatsby-link';
import React, { useEffect, useState } from 'react';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import { getApiRequest } from '../../../../../utils/request';
import Heading from '../../../../layout/admin/heading';
import OrderView from '../../bowler/order-view';
import Loadable from '../../../../data/loadable';
import Panel from '../../../../common/panel';
import SEO from '../../../../layout/seo';

export default function AdminOrderView({ orderId }) {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    const loadOrder = async () => {
      setLoading(true);
      try {
        const result = await getApiRequest(`/admin/order/${orderId}`);
        setOrder(result);
      } catch (err) {
        console.log(err, 'error loading order');
        navigate('/app/admin/orders');
        return;
      }
      setLoading(false);
    };

    setBreadcrumbs([
      {
        href: '/app/admin/orders',
        label: 'Orders',
      },
      {
        href: `/app/admin/order/view?id=${orderId}`,
        label: `View Order`,
      },
    ]);

    loadOrder();
  }, [orderId]);

  // console.log(order, 'order');

  return (
    <AdminContainer>
      <SEO title="View order" />
      <Heading title="View Order" />
      <Loadable loading={loading}>
        <div className="max-w-2xl mx-auto py-12">
          <Panel>
            {!!order && <OrderView order={order} />}
          </Panel>
        </div>
      </Loadable>
    </AdminContainer>
  );
}