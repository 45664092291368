import React from 'react';
import Money from '../../../data/money';
import { truncateString, formatPhoneFromServer, capFirstLetter, formatDay } from '../../../../utils/helpers';

function Products({ products }) {
  return (
    <>
      <h3 className="sr-only">Items</h3>
      {products.map((product) => (
        <div key={product.id} className="py-10 border-b border-gray-200 flex space-x-6">
          <img
            src={product.image}
            alt={product.name}
            className="flex-none w-20 h-20 object-center object-cover bg-gray-100 rounded-lg sm:w-40 sm:h-40"
          />
          <div className="flex-auto flex flex-col">
            <div>
              <h4 className="font-medium text-gray-900">
                {product.name}
              </h4>
              <p className="mt-2 text-sm text-gray-600">{truncateString(product.description, 200)}</p>
            </div>
            <div className="mt-6 flex-1 flex items-end">
              <dl className="flex text-sm divide-x divide-gray-200 space-x-4 sm:space-x-6">
                <div className="flex">
                  <dt className="font-medium text-gray-900">Quantity</dt>
                  <dd className="ml-2 text-gray-700">1</dd>
                </div>
                <div className="pl-4 flex sm:pl-6">
                  <dt className="font-medium text-gray-900">Price</dt>
                  <dd className="ml-2 text-gray-700"><Money value={product.price} /></dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default function OrderView({ order }) {
  const { products, discount_codes: discountCodes, total, name, email, zip, phone, created_at: createdAt, subtotal, discount, payment_details: paymentDetails, payment_type: type } = order;
  const { brand, last4 } = paymentDetails;
  const hasDiscount = discount && discount > 0;

  const code = hasDiscount && discountCodes ? discountCodes[0] : null;

  return (
    <div className="bg-white">
      <div className="max-w-3xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="max-w-xl">
          <dl className="text-sm font-medium">
            <dt className="text-gray-900">Order date</dt>
            <dd className="text-indigo-600 mt-2">{formatDay(createdAt, 'M/D/Y')}</dd>
          </dl>
        </div>
        <div className="mt-10 border-t border-gray-200">
          <Products products={products} />

          <div className="sm:ml-40 sm:pl-6">
            <h3 className="sr-only">Billing information</h3>

            <dl className="grid grid-cols-2 gap-x-6 text-sm py-10">
              <div>
                <dt className="font-medium text-gray-900">Billing info</dt>
                <dd className="mt-2 text-gray-700">
                  <address className="not-italic">
                    <span className="block">{name}</span>
                    <span className="block">{email}</span>
                    <span className="block">{formatPhoneFromServer(phone)}</span>
                    <span className="block">Zip: {zip}</span>
                  </address>
                </dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">Payment method</dt>
                
                {type === 'stripe' && (
                  <dd className="mt-2 text-gray-700">
                    <p>Credit Card</p>
                    <p>{capFirstLetter(brand)}</p>
                    <p>
                      <span aria-hidden="true">•••• </span>
                      <span className="sr-only">Ending in </span>{last4}
                    </p>
                  </dd>
                )}
              </div>
            </dl>


            <h3 className="sr-only">Summary</h3>

            <dl className="space-y-6 border-t border-gray-200 text-sm pt-10">
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Subtotal</dt>
                <dd className="text-gray-700"><Money value={subtotal} /></dd>
              </div>
              {!hasDiscount && (
                <div className="flex justify-between">
                  <dt className="flex font-medium text-gray-900">
                    Discount
                  </dt>
                  <dd className="text-gray-700">--</dd>
                </div>
              )}
              {hasDiscount && (
                <div className="flex justify-between">
                  <dt className="flex font-medium text-gray-900">
                    Discount
                    <span className="rounded-full bg-gray-200 text-xs text-gray-600 py-0.5 px-2 ml-2">{code && code.code}</span>
                  </dt>
                  <dd className="text-gray-700">-<Money value={discount} /></dd>
                </div>
              )}
              
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Total</dt>
                <dd className="text-gray-900"><Money value={total} /></dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
